import React, { useState } from "react";
import { Box, Flex, Text, Button } from "@chakra-ui/react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const MenuItems = ({ children, page }) => (
    <Link to={page}>
  <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
    {children}
  </Text>
  </Link>
);

const Header = (props) => {
  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);

  const data = useStaticQuery(graphql`query HeaderQuery {
  logoImg: file(
    sourceInstanceName: {eq: "images"}
    relativePath: {eq: "logo-transparent.png"}
  ) {
    childImageSharp {
      gatsbyImageData(width: 80, height: 80, layout: FIXED)
    }
  }
}
`);

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1.5rem"
      bg="brandGrey.50"
      color="white"
      {...props}
    >
      <Flex align="center" mr={5}>
        {/* logo-transparent.png */}
        <GatsbyImage
          image={data.logoImg.childImageSharp.gatsbyImageData}
          imgStyle={{ maxHeight: "initial" }}
          loading={'eager'}
          placeholder="blurred"
          alt="Create 15 Logo" />
        {/* <Heading as="h1" size="lg">
          Create 15
        </Heading> */}
      </Flex>

      <Box display={{ base: "block", sm: "none", md: "none" }} onClick={handleToggle}>
        <svg
          fill="black"
          width="12px"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Menu</title>
          <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
        </svg>
      </Box>

      <Box
        display={{ base: show ? "block" : "none", sm: "flex", md: "flex" }}
        width={{ base: "full", sm: "auto", md: "auto" }}
        alignItems="center"
        flexGrow={1}
        color="black"
      >
        <MenuItems page="/">Home</MenuItems>
        <MenuItems page="/about/">About</MenuItems>
        {/* <MenuItems page="/products/">Products</MenuItems> */}
        {/* <MenuItems page="/">Blog</MenuItems> */}
        <MenuItems page="/contact/">Contact</MenuItems>
      </Box>

      <Box
        display={{ base: show ? "block" : "none", sm: "flex", md: "flex" }}
        mt={{ base: 4, md: 0 }}
      >
          <Link to="/submit-design/">
        {/* <LinkOverlay> */}
        <Button colorScheme="brandOrange" bgColor="brandOrange.300">
          Submit Design
        </Button>
        {/* </LinkOverlay> */}
        </Link>
        
      </Box>
    </Flex>
  );
};

export default Header;
