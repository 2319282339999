import * as React from "react"
import { Center, Container } from "@chakra-ui/react"
import { Fonts } from "../utils/fonts"
import Header from "./header"

const Layout = ({children}) => {
  return (<>
  <Fonts/>
    <Header/>
    <Container as="main" bgColor="brandWhite" mt={4} maxW="4xl">
    {children}
    </Container>
    <Center as="footer" bg="brandGrey.50" h="100px" color="black" mt="20px">
  © {new Date().getFullYear()}, Create 15
    </Center>
    </>
  )
}

export default Layout